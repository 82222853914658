import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
    meta: {
      title: 'Give all your money a place to grow | Wealthfront'
    }
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import(/* webpackChunkName: "home" */ '../views/cash.vue'),
    meta: {
      title: 'Earn 5.00% APY with No Account Fees | Wealthfront Cash'
    }
  },
  {
    path: '/automated-bond-portfolio',
    name: 'automated-bond-portfolio',
    component: () => import(/* webpackChunkName: "home" */ '../views/automated-bond-portfolio.vue'),
    meta: {
      title: 'Automated Bond ETFs | Wealthfront'
    }
  },
  {
    path: '/investing',
    name: 'investing',
    component: () => import(/* webpackChunkName: "home" */ '../views/investing.vue'),
    meta: {
      title: 'Invest for the long term on your terms | Wealthfront'
    }
  },
  {
    path: '/socially-responsible-investing',
    name: 'socially-responsible-investing',
    component: () => import(/* webpackChunkName: "home" */ '../views/socially-responsible-investing.vue'),
    meta: {
      title: 'Socially Responsible Portfolios: SRI Made Easy | Wealthfront'
    }
  },
  {
    path: '/retirement',
    name: 'retirement',
    component: () => import(/* webpackChunkName: "home" */ '../views/retirement.vue'),
    meta: {
      title: 'Retirement Accounts built for you | Wealthfront'
    }
  },
  {
    path: '/college',
    name: 'college',
    component: () => import(/* webpackChunkName: "home" */ '../views/college.vue'),
    meta: {
      title: 'Automate Your 529 College Savings Plan | Wealthfront'
    }
  },
  {
    path: '/explore',
    name: 'explore',
    component: () => import(/* webpackChunkName: "home" */ '../views/explore.vue'),
    meta: {
      title: 'Make Wealth Your Own | Wealthfront'
    }
  },
  {
    path: '/stock-investing',
    name: 'stock-investing',
    component: () => import(/* webpackChunkName: "home" */ '../views/stock-investing.vue'),
    meta: {
      title: 'Your shortcut to smarter stock investing | Wealthfront'
    }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "home" */ '../views/pricing.vue'),
    meta: {
      title: 'Wealthfront Fees | Wealthfront'
    }
  },
  {
    path: '/tax-loss-harvesting',
    name: 'tax-loss-harvesting',
    component: () => import(/* webpackChunkName: "home" */ '../views/tax-loss-harvesting.vue'),
    meta: {
      title: 'Automated Tax-Loss Harvesting | Wealthfront'
    }
  },
  {
    path: '/historical-performance',
    name: 'historical-performance',
    component: () => import(/* webpackChunkName: "home" */ '../views/historical-performance.vue'),
    meta: {
      title: 'Historical Investment Performance | Wealthfront'
    }
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import(/* webpackChunkName: "home" */ '../views/reviews.vue'),
    meta: {
      title: 'Client Reviews | Wealthfront'
    }
  },
  {
    path: '/portfolio-line-of-credit',
    name: 'portfolio-line-of-credit',
    component: () => import(/* webpackChunkName: "home" */ '../views/portfolio-line-of-credit.vue'),
    meta: {
      title: 'Portfolio Line of Credit | Wealthfront'
    }
  },
  // {
  //   path: '/state',
  //   name: 'state',
  //   children: [
  //     {
  //       path: 'alabama',
  //       name: 'alabama',
  //       component: () => import(/* webpackChunkName: "alabama" */ '../views/state/alabama.vue'),
  //       meta: {
  //         title: 'Alabama - American Investment Council'
  //       }
  //     },
  //   ]
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})

export default router
