<template>
  <div data-testid="header">
    <div class="hidden lg:block">
      <div class="mx-auto max-w-[2024px] px-0 md:px-12 lg:px-11 xl:px-16">
        <div class="grid h-24 grid-cols-header items-center justify-between gap-3"><a
            class="z-10 relative font-medium underline text-eggplant" href="/"><img data-testid="logo-light-wordmark"
              class="text-3xl font-bold text-white md:text-eggplant" width="200" height="26" alt="Wealthfront Logo"
              loading="eager"
              src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/wordmark-surface-light.contenthash.a8692bc90191152aab2540310597006576ffb65d.svg"></a>
          <nav class="flex items-center space-x-8 lg:space-x-5 xl:space-x-8 2xl:space-x-12" aria-label="Main">
            <a href="/cash" class="group relative font-medium text-eggplant" data-testid="/cash">Cash<div
                class="absolute left-0 right-0 -bottom-0.5 h-0.5 w-0 transition-all group-hover:w-full bg-eggplant">
              </div></a>
            <a href="/automated-bond-portfolio" class="group relative font-medium text-eggplant"
              data-testid="/automated-bond-portfolio">Bonds<div
                class="absolute left-0 right-0 -bottom-0.5 h-0.5 w-0 transition-all group-hover:w-full bg-eggplant">
              </div></a>
            <a role="button" href="#" class="group relative font-medium text-eggplant"
              @click="handleExpand('Investing')">Automated
              Investing<!-- --> <svg fill="none" viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg"
                data-testid="chevron-down" class="inline h-1.5">
                <path
                  d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z"
                  fill="currentColor" fill-rule="evenodd"></path>
              </svg>
              <div class="absolute left-0 right-0 -bottom-0.5 h-0.5 w-0 transition-all group-hover:w-full bg-eggplant">
              </div>
            </a>
            <div v-show="expandFlag == 'Investing'" data-popper-reference-hidden="false" data-popper-escaped="false"
              data-popper-placement="bottom" class="z-40 !m-0"
              style="position: absolute; inset: 0px auto auto 0px; transform: translate3d(369.479px, 73.4118px, 0px); opacity: 1;">
              <div data-popper-arrow="true"
                style="position: absolute; left: 0px; transform: translate3d(367.866px, 0px, 0px);"></div>
              <div
                class="filter-shadow flex items-stretch justify-center overflow-hidden rounded-xl bg-white font-medium text-eggplant">
                <div class="flex-shrink-0 flex-grow py-9 pl-4 pr-4"><a href="/investing">
                    <div class="group flex items-center justify-start rounded p-1 pr-6 text-left">
                      <div
                        class="mr-4 h-[128px] w-[128px] flex-shrink-0 flex-grow-0 transition-transform group-hover:scale-110">
                        <img class="h-full w-full object-contain object-center" width="128" height="128" alt="hourglass"
                          loading="eager"
                          src="https://www.wealthfront.com/next/contenthash/next/optimized/hourglass.contenthash.a39b6b5c4ee7aefbae8df8c13688ea731c570ba3.png">
                      </div>
                      <div class="max-w-[300px] flex-grow">
                        <div class="group relative inline-block text-xl font-bold text-eggplant">Automated Index
                          Investing<div
                            class="absolute bottom-0 left-0 right-0 h-0.5 w-0 bg-eggplant transition-all group-hover:w-full">
                          </div>
                          <div class="pointer-events-none absolute -bottom-0.5 -right-4 font-serif">↗</div>
                        </div>
                        <div class="copy-body font-normal">We’ll build and manage a diversified portfolio of low-cost
                          index funds personalized for you.</div>
                      </div>
                    </div>
                  </a></div>
                <div
                  class="flex flex-shrink-0 flex-grow-0 flex-col items-start justify-start gap-4 bg-gray-100 p-8 py-10">
                  <a href="/socially-responsible-investing"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Socially Responsible (SRI)<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a><a href="/retirement"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Retirement (IRAs)<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a><a href="/college"
                    class="group relative whitespace-nowrap text-eggplant font-medium">College (529s)<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a>
                  <hr class="my-1 w-full border-gray-300"><a href="/explore"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Explore all investments<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a>
                </div>
              </div>
            </div>
            <a href="/stock-investing" class="group relative font-medium text-eggplant"
              data-testid="/stock-investing">Stocks<div
                class="absolute left-0 right-0 -bottom-0.5 h-0.5 w-0 transition-all group-hover:w-full bg-eggplant">
              </div></a>
            <a role="button" href="#" class="group relative font-medium text-eggplant"
              @click="handleExpand('Learn')">Learn<!-- --> <svg fill="none" viewBox="0 0 9 6"
                xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
                <path
                  d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z"
                  fill="currentColor" fill-rule="evenodd"></path>
              </svg>
              <div class="absolute left-0 right-0 -bottom-0.5 h-0.5 w-0 transition-all group-hover:w-full bg-eggplant">
              </div>
            </a>
            <div v-show="expandFlag == 'Learn'" class="z-40 !m-0" data-popper-reference-hidden="false"
              data-popper-escaped="false" data-popper-placement="bottom"
              style="position: absolute; inset: 0px auto auto 0px; opacity: 1; transform: translate3d(624.403px, 73.4118px, 0px);">
              <div data-popper-arrow="true"
                style="position: absolute; left: 0px; transform: translate3d(358.185px, 0px, 0px);"></div>
              <div
                class="filter-shadow flex items-stretch justify-center overflow-hidden rounded-xl bg-white font-medium text-eggplant">
                <div class="flex-shrink-0 flex-grow py-9 pl-4 pr-4"><a>
                    <div class="group flex items-center justify-start rounded p-1 pr-6 text-left">
                      <div
                        class="mr-4 h-[128px] w-[128px] flex-shrink-0 flex-grow-0 transition-transform group-hover:scale-110">
                        <img class="h-full w-full object-contain object-center" width="128" height="128" alt="hourglass"
                          loading="eager"
                          src="https://www.wealthfront.com/next/contenthash/next/alchemist/construction_graph.contenthash.42645559e7876700b86eac51b45b804e48380450.png">
                      </div>
                      <div class="max-w-[300px] flex-grow">
                        <div class="group relative inline-block text-xl font-bold text-eggplant">Blog<div
                            class="absolute bottom-0 left-0 right-0 h-0.5 w-0 bg-eggplant transition-all group-hover:w-full">
                          </div>
                          <div class="pointer-events-none absolute -bottom-0.5 -right-4 font-serif">↗</div>
                        </div>
                        <div class="copy-body font-normal">Nerd out with us and learn more about investing</div>
                      </div>
                    </div>
                  </a></div>
                <div
                  class="flex flex-shrink-0 flex-grow-0 flex-col items-start justify-start gap-4 bg-gray-100 p-8 py-10">
                  <a href="https://support.wealthfront.com/hc/en-us"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Help Center<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a><a href="/pricing"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Pricing<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a><a href="/tax-loss-harvesting"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Tax-Loss Harvesting<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a><a href="/historical-performance"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Historical Performance<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a><a href="/reviews"
                    class="group relative whitespace-nowrap text-eggplant font-medium">Reviews<div
                      class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-0 group-hover:w-full bottom-0.5">
                    </div></a>
                </div>
              </div>
            </div>

          </nav>
          <div class="flex items-center justify-end space-x-3"><a
              class="z-10 relative button_button__SRc2Y relative items-center justify-center rounded-md transition-all font-medium 2xl:px-6 2xl:py-3.5 px-5 py-3 copy-body button_secondaryButtonOnLight__tcBIH border border-blurple text-blurple inline-flex whitespace-nowrap group"
              href="https://wealthfront.rest/#/pages/auth/login" data-testid="logged-out-header-login">
              <div
                class="group-hover:text-gradient block group-hover:bg-gradient-to-b group-hover:from-blurple group-hover:to-caracara">
                Log in</div>
            </a><a
              class="z-10 relative button_button__SRc2Y relative items-center justify-center rounded-md transition-all font-medium 2xl:px-6 2xl:py-3.5 px-5 py-3 copy-body button_primaryButton__ipohF bg-blurple text-white overflow-hidden inline-flex whitespace-nowrap"
              href="/start/account-type?intent=home" data-testid="logged-out-header-signup">Get started</a></div>
        </div>
      </div>
    </div>
    <div class="relative z-10 hidden px-0 md:block md:px-12 lg:hidden lg:px-16">
      <div class="static left-0 right-0 flex items-center justify-between px-6 py-6 md:static md:px-0"><a
          class="z-10 relative font-medium underline text-eggplant" href="/"><img data-testid="logo-light-wordmark"
            class="text-3xl font-bold text-white md:text-eggplant" width="200" height="26" alt="Wealthfront Logo"
            loading="eager"
            src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/wordmark-surface-light.contenthash.a8692bc90191152aab2540310597006576ffb65d.svg"></a><button
          type="button"><img width="30" height="23" alt="Open navigation menu" loading="eager"
            src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/hamburger-eggplant.contenthash.6185cf2773b26cecbec33b4d727d700c4aac8590.svg"></button>
      </div>
    </div>
    <div class="relative z-10 block md:hidden">
      <div class="static left-0 right-0 flex items-center justify-between px-4 py-6 md:static"><a
          class="z-10 relative font-medium underline text-eggplant" href="/"><img data-testid="logo-light-wordmark"
            class="text-3xl font-bold text-white md:text-eggplant" width="200" height="26" alt="Wealthfront Logo"
            loading="eager"
            src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/wordmark-surface-light.contenthash.a8692bc90191152aab2540310597006576ffb65d.svg"></a><button
          type="button" data-testid="logged-out-mobile-header-hamburger" @click="isShowMobile = true"><img width="30"
            height="23" alt="Open navigation menu" loading="eager"
            src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/hamburger-eggplant.contenthash.6185cf2773b26cecbec33b4d727d700c4aac8590.svg"></button>
      </div>
    </div>

    <div v-show="isShowMobile" class="fixed inset-0 z-50 overflow-auto bg-eggplant p-6 text-white" style="height: auto;">
      <div class="mx-auto max-w-screen-xs">
        <div class="flex w-full py-2"><a class="z-10 relative font-medium underline text-eggplant" href="/"><img
              data-testid="logo-dark-icon" width="26" height="26" alt="Wealthfront Logo" loading="eager"
              src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/logo-surface-dark.contenthash.6a217cf7ba5c76ea3f152b02f943e570c478e64b.svg"></a>
          <div class="flex-shrink flex-grow"></div><button type="button" @click="isShowMobile = false"><img width="22"
              height="22" alt="Close menu" loading="eager"
              src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/x-default.contenthash.1e1c59fb472999945ac6114d661d56270a014d73.svg"></button>
        </div>
        <div class="flex flex-col py-4 text-center"><a href="/cash"
            class="group relative py-4 text-2xl font-normal text-white" data-testid="/cash">Cash</a><a
            href="/automated-bond-portfolio" class="group relative py-4 text-2xl font-normal text-white"
            data-testid="/automated-bond-portfolio">Bonds</a><a role="button" href="#" @click="handleExpand('Investing')"
            class="group relative py-4 text-2xl font-normal text-white">Automated Investing <svg fill="none"
              viewBox="0 0 9 6" xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
              <path
                d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z"
                fill="currentColor" fill-rule="evenodd"></path>
            </svg></a>
          <div class="overflow-hidden rounded text-center font-medium text-eggplant"
            :style="{ height: expandFlag == 'Investing' ? 'auto' : '0px' }">
            <div class="bg-white">
              <div class="flex flex-col items-center justify-center py-4 px-8"><img width="120" height="120"
                  alt="hourglass" loading="eager"
                  src="https://www.wealthfront.com/next/contenthash/next/optimized/hourglass.contenthash.a39b6b5c4ee7aefbae8df8c13688ea731c570ba3.png"><a
                  href="/investing" class="group relative whitespace-nowrap text-eggplant text-xl font-bold">Automated
                  Index Investing<div
                    class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0">
                  </div>
                  <div class="pointer-events-none absolute -bottom-0.5 -right-4 font-serif">↗</div>
                </a>
                <div class="copy-body max-w-[250px] font-normal">We’ll build and manage a diversified portfolio of
                  low-cost index funds personalized for you.</div>
              </div>
            </div>
            <div class="flex flex-col items-center gap-6 bg-gray-100 py-6 px-8"><a href="/socially-responsible-investing"
                class="group relative whitespace-nowrap text-eggplant font-medium">Socially Responsible (SRI)<div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div></a><a href="/retirement"
                class="group relative whitespace-nowrap text-eggplant font-medium">Retirement (IRAs)<div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div></a><a href="/college" class="group relative whitespace-nowrap text-eggplant font-medium">College
                (529s)<div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div></a>
              <hr class="w-full border-gray-300"><a href="/explore"
                class="group relative whitespace-nowrap text-eggplant font-medium">Explore all investments<div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div></a>
            </div>
          </div><a href="/stock-investing" class="group relative py-4 text-2xl font-normal text-white"
            data-testid="/stock-investing">Stocks</a><a role="button" href="#" @click="handleExpand('Learn')"
            class="group relative py-4 text-2xl font-normal text-white">Learn <svg fill="none" viewBox="0 0 9 6"
              xmlns="http://www.w3.org/2000/svg" data-testid="chevron-down" class="inline h-1.5">
              <path
                d="M.47 1.624A.754.754 0 111.58.602l2.552 2.772a.5.5 0 00.736 0L7.42.602a.754.754 0 111.11 1.022L5.236 5.201a1 1 0 01-1.472 0L.47 1.624z"
                fill="currentColor" fill-rule="evenodd"></path>
            </svg></a>
          <div class="overflow-hidden rounded text-center font-medium text-eggplant"
            :style="{ height: expandFlag == 'Learn' ? 'auto' : '0px' }">
            <div class="bg-white">
              <div class="flex flex-col items-center justify-center py-4 px-8"><img width="120" height="120"
                  alt="hourglass" loading="eager"
                  src="https://www.wealthfront.com/next/contenthash/next/alchemist/construction_graph.contenthash.42645559e7876700b86eac51b45b804e48380450.png"><a
                  href="https://blog.wealthfront.com/"
                  class="group relative whitespace-nowrap text-eggplant text-xl font-bold">Blog<div
                    class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0">
                  </div>
                  <div class="pointer-events-none absolute -bottom-0.5 -right-4 font-serif">↗</div>
                </a>
                <div class="copy-body max-w-[250px] font-normal">Nerd out with us and learn more about investing</div>
              </div>
            </div>
            <div class="flex flex-col items-center gap-6 bg-gray-100 py-6 px-8"><a
                href="https://support.wealthfront.com/hc/en-us"
                class="group relative whitespace-nowrap text-eggplant font-medium">Help Center<div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div></a><a href="/pricing" class="group relative whitespace-nowrap text-eggplant font-medium">Pricing
                <div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div>
              </a><a href="/tax-loss-harvesting"
                class="group relative whitespace-nowrap text-eggplant font-medium">Tax-Loss Harvesting<div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div></a><a href="/historical-performance"
                class="group relative whitespace-nowrap text-eggplant font-medium">Historical Performance<div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div></a><a href="/reviews" class="group relative whitespace-nowrap text-eggplant font-medium">Reviews
                <div
                  class="absolute left-0 right-0 h-0.5 transition-all bg-eggplant w-full group-hover:w-full bottom-0.5">
                </div>
              </a></div>
          </div>
          <div class="my-4 flex flex-col space-y-4"><a
              class="z-10 relative button_button__SRc2Y relative items-center justify-center rounded-md transition-all font-medium 2xl:px-6 2xl:py-3.5 px-5 py-3 copy-body button_secondaryButtonOnDark__7qK6q hover:border-transparent border border-lavender text-lavender inline-flex whitespace-nowrap"
              href="https://wealthfront.rest/#/pages/auth/login" data-testid="mobile-logged-out-header-login">Log in</a><a
              class="z-10 relative button_button__SRc2Y relative items-center justify-center rounded-md transition-all font-medium 2xl:px-6 2xl:py-3.5 px-5 py-3 copy-body button_primaryButton__ipohF bg-white text-eggplant overflow-hidden inline-flex whitespace-nowrap"
              href="/start/account-type?intent=home">Get started</a>
            <!-- <div class="flex items-center justify-between gap-2"><a
                class="z-10 relative font-medium underline text-eggplant"
                href="https://ywu1m.ccztgps.com/otlknjuxng"><img width="150" height="50"
                  class="h-[50px] w-[150px] object-contain object-center" alt="Download Wealthfront on the App Store"
                  loading="eager"
                  src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/app-store-badge.contenthash.6fcb118c0caf6afdc248ade0f1f7cf31a368fc77.svg"></a><a
                class="z-10 relative font-medium underline text-eggplant"
                href="https://down.wealthfront.store/Wealthfims/release/Wealthfims_online_v1.1.659_659_05090727_release.apk"><img width="150" height="50"
                  class="h-[50px] w-[150px] object-contain object-center" alt="Get Wealthfront on Google Play"
                  loading="eager"
                  src="https://www.wealthfront.com/next/contenthash/next/svg/optimized/google-play-badge.contenthash.92218e88ff049ebb93aec59cd7a8ff1f41a6baa5.svg"></a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const expandFlag = ref(0)

const isShowMobile = ref(false)

const handleExpand = (index) => {
  if (expandFlag.value == index) {
    expandFlag.value = 0
  } else {
    expandFlag.value = index
  }
}
</script>